<template>
<div class="w-full">
  <item-card>
    <template v-slot:title>
      <div class="flex flex-wrap items-center mb-2">
        <h3 v-if="customer" class="text-lg leading-6 font-medium text-gray-900 dark:text-gray-300 mr-5">
          Pievienot jaunu cenu
        </h3>
      </div>

      <div class="flex ml-auto items-center mb-2">
        <Button icon="close" @click="hideForm" />
      </div>
    </template>

    <template v-slot:content>

      <Autocomplete placeholder="Meklēt ierakstu katalogā" v-model="form.autocompleteValue" :errors="errors.autocompleteValue" :url="urlForFetch" :params="{ ignored_ids: ignoredIds }" @autocompleteItem="getPriceableItem" :clearOnClick="true" />

      <template v-if="priceableItem">
        <div class="flex flex-col w-full">
          <div class="pt-4">
            <div class="sm:flex flex-wrap">
              <item-text title="Nosaukums" :text="priceableItem.name" />
              <item-text title="Kods" :text="priceableItem.sku ? priceableItem.sku :'N/A'" />

              <div class="flex flex-col mt-1 w-32">
                <Input type="number" :placeholder="'EUR / ' + priceableItem.measure_unit.short_name" v-model="form.price" :errors="errors.price" />
              </div>
            </div>
          </div>
        </div>
      </template>

    </template>

    <template v-slot:additional>

      <div class="flex justify-end">
        <template v-if="priceableItem">
          <template v-if="!loading">
            <button type="button" class="flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-gradient-to-r from-button2-from to-button2-to hover:from-button2-to hover:to-button2-from focus:outline-none focus:border-none transition duration-500 ease-in-out" @click.prevent="submit">
              Saglabāt
            </button>
          </template>
          <template v-else>
            <div class="flex items-center">
              <Loading />
            </div>
          </template>
        </template>

        <button type="button" class="bg-white dark:bg-gray-750 py-2 px-4 border border-gray-300 dark:border-gray-500 rounded-md shadow-sm text-sm font-medium text-gray-700 dark:text-gray-300 hover:bg-gray-50 dark:hover:bg-gray-770 focus:outline-none focus:ring-0 ml-2" @click="hideForm">
          Atcelt
        </button>
      </div>

    </template>
  </item-card>
</div>
</template>

<script>
import {mapGetters} from "vuex";
import Autocomplete from "@/components/Components/Autocomplete";
import ItemCard from "@/components/Components/ItemCard";
import ItemText from "@/components/Components/ItemText";
import Input from "@/components/Components/Input";
import Validation from "@/modules/Validation";
import Loading from "@/components/Components/Loading";

export default {
  name: "CreateCustomerPrice",
  props: ['urlForFetch', 'ignoredIds', 'type'],
  components: {
    Autocomplete,
    ItemCard,
    ItemText,
    Input,
    Loading,
  },
  data: () => ({
    priceableItem: null,
    price: '',
    form: {
      autocompleteValue: null,
      price: null
    },
  }),
  computed: {
    ...mapGetters({
        loading: 'loading',
        customer: 'customer',
        errors: "errors"
    }),
    formValidation() {
      return {
        price: {
          rules: ['required']
        },
      }
    }
  },
  methods: {
    getPriceableItem(value) {
        this.form.price = value.price
        this.priceableItem = value
    },
    hideForm() {
        this.$emit('hide-form')
    },
    submit() {
        this.$Progress.start();
        if (Validation(this.formValidation, this.form)) {
            this.$store.dispatch("createCustomerPrice", {
                data: {
                    priceable_id: this.priceableItem.id,
                    priceable_type: this.type,
                    price: this.form.price,
                },
                customerId: this.customer.id,
            }).then(response => {
                this.hideForm()
            })
        } else this.$Progress.fail();
    }
  }
}
</script>

<style>

</style>