<template>
  <item-card>
    <template v-slot:title>
      <div class="flex flex-wrap items-center mb-2">
        <h3 v-if="customer" class="text-lg leading-6 font-medium text-gray-900 dark:text-gray-300 mr-5">
          {{label}}
        </h3>
        <template v-if="prices.length > 0">
          <Checkbox text="Rādīt cenas" v-model="showPrices" />
        </template>
      </div>

      <div class="flex ml-auto items-center mb-2">
        <template v-if="prices.length > 1">
          <Input name="search" placeholder="Meklēt" v-model="localSearch" :showPlaceholder="false" />
        </template>

        <Button icon="plus" @click="showAddPrice = !showAddPrice" />
      </div>
    </template>

    <template v-slot:content>
      <template v-if="showAddPrice">
        <CreateCustomerPrice @hide-form="hideCreateForm" :url-for-fetch="resourceUrl" :type="type" :ignoredIds="ignoredIds" />
      </template>

      <template v-if="showPrices && prices.length > 0">
        <div class="pt-4 w-full divide-y divide-gray-200 dark:divide-gray-700">
          <SinglePrice v-for="(price, index) in allPrices" :key="index" :price="price" />
        </div>
      </template>

      <template v-if="!showAddPrice && prices.length < 1">
        <div class="w-full">
          <p class="text-center text-lg text-gray-400 mb-3">{{label}} nav  pievienotas</p>
          <button type="button" class="block mx-auto items-center px-3 py-2 border border-gray-300 dark:border-gray-500 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 dark:text-gray-300 bg-white dark:bg-gray-750 hover:bg-gray-50 dark:hover:bg-gray-770 dark:hover:bg-gray-770 focus:outline-none focus:ring-0 hover:bg-gray-100 transition duration-500 ease-in-out" @click="showAddPrice = !showAddPrice">
            Pievienot
          </button>
        </div>
      </template>

    </template>
  </item-card>
</template>

<script>
import { mapGetters } from 'vuex'
import ItemCard from "@/components/Components/ItemCard"
import SinglePrice from "@/components/Customer/Prices/SinglePrice"
import Checkbox from "@/components/Components/Checkbox";
import Input from "@/components/Components/Input";
import CreateCustomerPrice from "@/components/Customer/Prices/CreateCustomerPrice";

export default {
  name: "SingleTypePrices",
  components: {
    ItemCard,
    SinglePrice,
    Checkbox,
    Input,
    CreateCustomerPrice
  },
  props: ['resourceUrl', 'prices', 'label', 'type'],
  data: () => ({
    showAddPrice: false,
    localSearch: "",
    allPrices: [],
    showPrices: true,
  }),
  computed: {
    ...mapGetters({
      customer: 'customer',
    }),
    ignoredIds : function () {
      return this.prices.map(price => {
        return price.item_id
      })
    }
  },
  watch: {
    prices() {
      this.allPrices = [...this.prices];
    },
    localSearch() {
      this.allPrices = this.prices.filter(price => {
        if (price.name.toLowerCase().includes(this.localSearch.toLowerCase())
        || price.sku.toLowerCase().includes(this.localSearch.toLowerCase())
        || price.price.toLowerCase().includes(this.localSearch.toLowerCase())) {
          return price;
        }
      })
      this.showPrices = true;
    }
  },
  methods: {
    hideCreateForm() {
      this.showAddPrice = false
    }
  },
  mounted() {
    this.allPrices = [...this.prices];
  }
}
</script>

<style>

</style>