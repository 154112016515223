<template>
  <Content :search="false">
    <template v-slot:beforeSearch>
      <div class="flex items-center text-lg font-semibold dark:text-gray-300">
        <Button icon="arrow-left" @click="goBack" />
        <template v-if="customer">
          <h2>{{ customer.full_name }} cenu lapa</h2>
        </template>
      </div>
    </template>
    
    <template v-slot:content>
      <div>
        <template v-if="prices">
          <div class="sm:rounded-md mb-3">
            <ul role="list" class="">
              <SingleTypePrices label="Kataloga cenas"
                :prices="prices.catalog_items"
                resourceUrl="/api/fetch_catalog_items"
                type="App\CatalogItem" />

            </ul>
          </div>
        </template>
      </div>
    </template>
  </Content>
</template>

<script>
import { mapGetters } from 'vuex'
import SingleTypePrices from "@/components/Customer/Prices/SingleTypePrices"

export default {
  name: "CustomerPrices",
  components: {
    SingleTypePrices
  },
  mounted() {
    this.$store.dispatch('clearCustomer')
    this.$store.dispatch('removeAllFormsForDisplay')
    this.$store.dispatch('getCustomer', this.$route.params.customerId)
    this.$store.dispatch('getCustomerPrices', this.$route.params.customerId)
  },
  computed: {
    ...mapGetters({
      customer: 'customer',
      prices: 'customerPrices'
    })
  },
  methods: {
    goBack(){
      this.$router.go(-1)
    },
  }
}
</script>

<style>

</style>