<template>
  <div class="flex flex-wrap lg:flex-nowrap w-full pt-3 pb-3">

    <div class="grid grid-cols-12 w-full">
      <div class="col-span-12 sm:col-span-6 lg:col-span-4 xl:col-span-3">
        <item-text title="Nosaukums" :text="price.name" />
      </div>
      <div class="col-span-12 sm:col-span-6 lg:col-span-3 xl:col-span-2">
        <item-text title="Kods" :text="price.sku ? price.sku :'N/A'" />
      </div>

      <template v-if="!showEditForm">
        <div class="col-span-12 sm:col-span-6 lg:col-span-3">
          <item-text :title="'EUR / ' + price.measure_unit.short_name" :text="price.price" />
        </div>
      </template>

      <template v-if="showEditForm">
        <div class="col-span-12 sm:col-span-6 lg:col-span-3">
          <div class="flex flex-col">
            <Input type="number" :placeholder="'EUR / ' + price.measure_unit.short_name" v-model="form.price" :errors="errors.price" />

            <div class="pt-3" :class="errors.price ? 'mt-2.5' : ''">
              <div class="flex justify-end">
                <template v-if="!loading">
                  <button type="button" class="flex justify-center px-2.5 py-1.5 border border-transparent rounded-md shadow-sm text-xs font-medium text-white bg-gradient-to-r from-button2-from to-button2-to hover:from-button2-to hover:to-button2-from focus:outline-none focus:border-none transition duration-500 ease-in-out mr-2" @click.prevent="editPrice">
                    Saglabāt
                  </button>
                </template>
                <template v-else>
                  <div class="flex items-center">
                    <Loading />
                  </div>
                </template>

                <button type="button" class="bg-white dark:bg-gray-750 px-2.5 py-1.5 border border-gray-300 dark:border-gray-500 rounded-md shadow-sm text-xs font-medium text-gray-700 dark:text-gray-300 hover:bg-gray-50 dark:hover:bg-gray-770 focus:outline-none focus:ring-0" @click="hideEditForm">
                  Atcelt
                </button>
              </div>
            </div>
          </div>
        </div>
      </template>
    </div>
    

    <div class="ml-auto" v-show="!showEditForm">
      <p class="mt-2 flex items-center text-sm font-semibold text-gray-900 dark:text-gray-300 sm:mt-0 h-full">
        <Button icon="pencil" @click="showEditForm = true" />

        <Button icon="delete" @click="deletePrice" />
      </p>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import ItemText from "@/components/Components/ItemText"
import Input from "@/components/Components/Input";
import Validation from "@/modules/Validation";
import Loading from "@/components/Components/Loading";

export default {
  name: "SinglePrice",
  components: {
    ItemText,
    Input,
    Loading
  },
  props: ['price'],
  data: () => ({
    showEditForm: false,
    form: null
  }),
  computed: {
    ...mapGetters({
      customer: 'customer',
      loading: 'loading',
      errors: "errors"
    }),
    formValidation() {
      return {
        price: {
          rules: ['required']
        },
      }
    }
  },
  methods:{
    hideEditForm() {
      this.showEditForm = false
      this.$store.dispatch('getCustomerPrices', this.$route.params.customerId)
      this.$store.dispatch("setErrors", {})
    },
    editPrice() {
      this.$Progress.start();
      if (Validation(this.formValidation, this.form)) {
        this.$store.dispatch("updateCustomerPrice", {
          price: this.form.price,
          customerPriceId: this.price.id,
          customerId: this.customer.id
        }).then(response => {
          this.showEditForm = false
        })
      } else this.$Progress.fail();
    },
    deletePrice() {
      if(confirm('Vai esi drošs?')){
        this.$Progress.start()
        this.$store.dispatch('deleteCustomerPrice', {
          customerId: this.customer.id,
          customerPriceId: this.price.id
        })
      }
    }
  },
  mounted() {
    this.form = {
      price: this.price.price
    }
  }
}
</script>

<style>

</style>